export const EMAIL_OTP_INITIATE_MUTATION = `mutation (
    $site: String!,
    $device: EntitlementDevice!
    $email: String!,
    $emailConsent: Boolean!,
    $campaign: String!,
    $campaignSource: String!,
    $campaignMedium: String!,
    $deviceName : String!,
  ) {
    identityAuthOtpInitiate(
     site:$site
     device:$device
     input:{
      email: $email,
      emailConsent: $emailConsent,
      deviceName: $deviceName,
      campaign :$campaign,
      campaignSource: $campaignSource,
      campaignMedium:$campaignMedium
     }
    ) {
       key
    }
  }
  `

  export const PHONE_OTP_INITIATE_MUTATION = `mutation (
    $site: String!,
    $device: EntitlementDevice!
    $phoneNumber: String!,
    $campaign: String!,
    $campaignSource: String!,
    $campaignMedium: String!,
    $deviceName : String!,
  ) {
    identityAuthOtpInitiate(
     site:$site
     device:$device
     input:{
      phoneNumber: $phoneNumber,
      deviceName: $deviceName,
      campaign :$campaign,
      campaignSource: $campaignSource,
      campaignMedium:$campaignMedium
     }
    ) {
       key
    }
  }
  `
  export const OTP_VALIDATE = `mutation (
      $site: String!,
      $key:String,
      $otp: String,
 	 	  $auth: String,
    ) {
      identityAuthOtpValidate(
        site:$site
        key:$key
        otp: $otp
        auth: $auth
      ) {
        userId
        refreshToken
        email
        authorizationToken
        provider
        phoneNumber
        existingUser
        isSubscribed
      }
    }`
export const GOOGLE_VALIIDATE = `mutation (
  $site: String!,
  $device:EntitlementDevice!,
  $input: SignInGoogleInput!
) {
  identitySigninGoogle(
    site:$site
    device:$device
    input: $input
  ) {
    userId
    refreshToken
    email
    authorizationToken
    provider
    phoneNumber
    existingUser
    isSubscribed
  }
}`
export const APPLE_VALIIDATE = `mutation (
  $site: String!,
  $device:EntitlementDevice!,
  $input: AppleSignInInput!
) {
  identitySignInApple(
    site:$site
    device:$device
    input: $input
  ) {
    userId
    refreshToken
    email
    authorizationToken
    provider
    phoneNumber
    existingUser
    isSubscribed
  }
}`

export const INITIATE_SUBSCRIPTION = `mutation ($site: String!,$req:CreateAccountAndSubscriptionMetadata) {
  createAccountAndSubscription(
    site:$site
    req: $req
  ) {
  subscription {
    subscriptionStatus
  }
  }
}`