export const IDENTITY_USER = `query GetUserProfile($site: String!){
    identityUserProfile(site: $site) {
        registeredOn
        whatsappConsent
        lastLoginDate
        registrationIp
        site
        email
        vppaCompliance
        countryName
        country
        name
        nickname
        state
        city
        tveUserId
        registerdVia
        emailConsent
        registeredDevice
        userId
        provider
        id
        phone
        passwordEnabled
        providers
        stateName
        postalcode
        isActive
        isVip
        timezone
        lastLoginIp
        profiles {
            profileName
            userId
            isDefault
            addedDate
            site
            profileType
            profileId
            alertPersonalization {
                enabled
                start
                quarter
                final
            }
            contentPersonalization {
                teams {
                    id
                    title
                }
                persons {
                    id
                    title
                }
            }
            isContentPersonalizationViewed
        }
    tveMetadata{
        site
        userId
        tveProvider
        mvpdProvider
        planId
        deviceId
        adobeZip
      idpLogo
      idpName
      tveRedirectUrl
        tveUserId
    }
        monetizationPlan {
            identifier
            metadata{
                name
                value
            }
            updateDate
            addedDate
            featureSetting {
                numberOfAllowedStreams
                contentConsumption
                includingAds
                isHdStreaming
                isBeamingAllowed
                isDownloadAllowed
                numberOfAllowedDevices
            }
            scheduledFromDate
            description
            site
            planDetails {
                isRentEnabled
                redirectUrl
                isPurchaseEnabled
                recurringPaymentAmount
                recurringPaymentCurrencyCode
                countryCode
                isDefault
                displayStrikeThroughPrice
                scheduledFromDate
                supportedDevices
                visible
                taxIncluded
                numberOfAllowedStreams
                numberOfAllowedDevices
                title
                description
                hidePlanPrice
            }
            objectKey
            renewable
            renewalCycleType
            name
            renewalCyclePeriodMultiplier
            siteOwner
            id
            planDisplayOrder
            monetizationModel
            identifier_vizio_in
            identifier_vizio_us
        }
        userState
        phoneNumber
        unverifiedEmail
        unverifiedPhone
        mvpdProvider
        hbaMetadata {
            enabled
            latitude
            longitude
            ip
            zip
            zipByIp
            deviceId
            lookupMode
            type
        }
    }
}`
