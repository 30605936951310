// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snackbar {
  height: 40px;
  position: fixed;
  top: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  animation: fadein 0.3s;
  z-index: 25;
}
.snackbar .message-box {
  color: white;
  font-size: 14px;
}
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

.snackbar.error {
  background: #ff5e5e;
}

.snackbar.success {
  background: #219653;
}`, "",{"version":3,"sources":["webpack://./client/components/Snackbar/index.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;AACJ;AACI;EACI,YAAA;EACA,eAAA;AACR;AAEI;EACI;IACI,SAAA;IACA,UAAA;EAAV;EAGM;IACI,YAAA;IACA,UAAA;EADV;AACF;;AAKA;EACI,mBAAA;AAFJ;;AAKA;EACI,mBAAA;AAFJ","sourcesContent":[".snackbar {\n    height: 40px;\n    position: fixed;\n    top: 70px;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    visibility: visible;\n    animation: fadein 0.3s;\n    z-index: 25;\n\n    .message-box {\n        color: white;\n        font-size: 14px;\n    }\n\n    @keyframes fadein {\n        from {\n            bottom: 0;\n            opacity: 0;\n        }\n\n        to {\n            bottom: 30px;\n            opacity: 1;\n        }\n    }\n}\n\n.snackbar.error {\n    background: #ff5e5e;\n}\n\n.snackbar.success {\n    background: #219653;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
