// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  padding: 0.5rem;
  width: 1em;
  height: 1em;
  margin: 10px auto;
  border: 2px solid transparent;
  border-top: 3px solid currentColor;
  border-radius: 100%;
  animation: spin 1s infinite linear;
}

#brand-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
}
#brand-loader img {
  background: transparent;
  z-index: 9;
  height: 150px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.video-loader {
  position: absolute;
  top: 36%;
  left: 44%;
  border-radius: 100%;
  width: 100px;
  height: 100px;
}
.video-loader img {
  width: inherit;
}

@media (max-width: 600px) {
  .video-loader {
    position: absolute;
    top: 23%;
    left: 36%;
    border-radius: 100%;
    width: 90px;
    height: 90px;
  }
}
@media (max-width: 1024px) {
  .video-loader {
    position: absolute;
    top: 29%;
    left: 38%;
    border-radius: 100%;
    width: 100px;
    height: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./client/components/spinner/index.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,UAAA;EACA,WAAA;EACA,iBAAA;EACA,6BAAA;EACA,kCAAA;EACA,mBAAA;EACA,kCAAA;AACJ;;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACJ;AAKI;EAEE,uBAAA;EACA,UAAA;EACA,aAAA;AAJN;;AAQE;EACE;IAAO,uBAAA;EAJT;EAKE;IAAK,yBAAA;EAFP;AACF;AAIE;EACE,kBAAA;EACE,QAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;AAFN;AAIM;EACE,cAAA;AAFR;;AAME;EACE;IACE,kBAAA;IACA,QAAA;IACA,SAAA;IACA,mBAAA;IACA,WAAA;IACA,YAAA;EAHJ;AACF;AAME;EACE;IACE,kBAAA;IACA,QAAA;IACA,SAAA;IACA,mBAAA;IACA,YAAA;IACA,aAAA;EAJJ;AACF","sourcesContent":[".spinner {\n    padding: 0.5rem;\n    width: 1em;\n    height: 1em;\n    margin: 10px auto;\n    border: 2px solid transparent;\n    border-top: 3px solid currentColor;\n    border-radius: 100%;\n    animation: spin 1s infinite linear;\n  }\n\n  #brand-loader {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    // height: 100vh;\n    // width: 100vw;\n    // position: absolute;\n\n\n    img{\n      // border-radius: 50%;\n      background: transparent;\n      z-index: 9;\n      height: 150px;\n    }\n  }  \n  \n  @keyframes spin {\n    from { transform: rotate(0deg); }\n    to { transform: rotate(360deg); }\n  }\n\n  .video-loader{\n    position: absolute;\n      top: 36%;\n      left: 44%;\n      border-radius: 100%;\n      width: 100px;\n      height: 100px;\n\n      img{\n        width: inherit;\n      }\n  }\n\n  @media (max-width:600px){\n    .video-loader{\n      position: absolute;\n      top: 23%;\n      left: 36%;\n      border-radius: 100%;\n      width: 90px;\n      height: 90px;\n    }\n  }\n\n  @media (max-width:1024px){\n    .video-loader{\n      position: absolute;\n      top: 29%;\n      left: 38%;\n      border-radius: 100%;\n      width: 100px;\n      height: 100px;\n    }\n  }\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
