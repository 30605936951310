import { getToken } from "../helpers"
import { APPLE_VALIIDATE, EMAIL_OTP_INITIATE_MUTATION, GOOGLE_VALIIDATE, INITIATE_SUBSCRIPTION, OTP_VALIDATE, PHONE_OTP_INITIATE_MUTATION } from "./Queries/loginInitiateQuery"
import { IDENTITY_USER } from "./Queries/identityUser";
import request from 'graphql-request';
import { generateMinifiedQuery } from "./hash";
import logger from "../logger";

let requestMap = {
    "initiateEmail" : EMAIL_OTP_INITIATE_MUTATION,
    "initiatePhone" : PHONE_OTP_INITIATE_MUTATION,
    "initiateEmailValidate" : OTP_VALIDATE,
    "initiatePhoneValidate" : OTP_VALIDATE,
    "googleValidate" : GOOGLE_VALIIDATE,
    "appleValidate" : APPLE_VALIIDATE,
    "intiateSubscription" : INITIATE_SUBSCRIPTION,
    "userIdentity" : IDENTITY_USER
}
export const callRequest = ({type,variables,cb,errorHandling}) => {
    if (!requestMap[`${type}`]) return

    let baseUrl = window?.app_data?.appcmsMain?.apiConfig?.apiBaseUrl		
    let endpoint = `${baseUrl}/graphql`
    const minifiedQuery = generateMinifiedQuery(requestMap[`${type}`]);
    return getToken().then((token)=> {
      let headers = {
        "authorization": token,
        "x-api-key" : window?.xApiKey
     }
      try {
        return request(endpoint, minifiedQuery, variables, headers).then((res) => {
          logger(res)
          return res
      })
      }
      catch(e) {
        if (typeof errorHandling == "function") {
          errorHandling()
        }
        return e
      }
}).catch((e) => {
  logger(e, "error")
  throw Error(e?.response?.errors?.length ? JSON.stringify(e.response.errors[0]?.extensions?.code || e.response.errors[0]?.message) : JSON.stringify(e?.response?.errors))
}).finally(() => {
    cb()
})
}