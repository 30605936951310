import { generateHash, generateMinifiedQuery } from "../hash"
export const categoryDetailPageQuery = `query Category($site: String!, $path: String, $pageId: String, $device: Device!, $includeContent: Boolean, $moduleLimit: Int, $moduleOffset: Int, $languageCode: String, $countryCode: String, $userState: String, $modules: [String!], $offset: Int, $contentType: String, $next: String) {
    page(
      site: $site
      path: $path
      pageId: $pageId
      device: $device
      includeContent: $includeContent
      moduleLimit: $moduleLimit
      moduleOffset: $moduleOffset
      languageCode: $languageCode
      countryCode: $countryCode
      userState: $userState
      modules: $modules
      contentType: $contentType
      offset: $offset
      next: $next
    ) {
      id
      moduleCount
      moduleIds
      title
      metadataMap {
        title
        keywords
        description
        h1Title
        h2Title
        contentType
        contentUrl
        image
        isLiveStream
        duration
        uploadDate
        thumbnailUrl {
          r16x9
        }
      }
      modules {
        id
        moduleType
        ... on CategoryDetailModule {
          title
          count
          contentType
          next
          contentData {
            id
            ...PartialGist
            ... on Video {
              ...PartialVideo_1
            }
            ... on Series {
              monetizationBadgeIdentifier
              plans {
                type
                planIds
                planGroupIds
              }
            }
          }
          layout {
            ...Layout
          }
        }
      }
    }
  }
  fragment PartialGist on Content {
    id
    gist {
      id
      title
      contentType
      permalink
      imageGist {
        r32x9
        r16x9
        r4x3
        r3x4
        r1x1
        r9x16
      }
    }
  }
  fragment PartialVideo_1 on Video {
    monetizationBadgeIdentifier
    parentalRating
    runtime
    metadata {
      name
      value
    }
  }
  fragment Layout on ModuleLayout {
    id
    settings
    styles
    view
    type
    blockName
    isMasthead
  }`

export const minifiedCategoryPageQuery=generateMinifiedQuery(categoryDetailPageQuery)
export const CategoryPageQuerySHA = generateHash(minifiedCategoryPageQuery)