import React from "react";
import { subResourceIntegrity } from "../client/Graphql/hash";
function extractMetaTag(metaTagString) {
    if (metaTagString) {
        var nameMatch = metaTagString.match(/name="([^"]*)"/);
        var contentMatch = metaTagString.match(/content="([^"]*)"/);
        var name_1 = nameMatch ? nameMatch[1] : '';
        var content = contentMatch ? contentMatch[1] : '';
        return { name: name_1, content: content };
    }
    return null;
}
export default function Html(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40;
    var siteConfig = _a.siteConfig, pageData = _a.pageData, browserDetails = _a.browserDetails, children = _a.children, title = _a.title, xApiKey = _a.xApiKey, cachedAPI = _a.cachedAPI, apiBaseUrl = _a.apiBaseUrl, sseBaseUrl = _a.sseBaseUrl, analyticsBaseUrl = _a.analyticsBaseUrl, wssBaseUrl = _a.wssBaseUrl, env = _a.env, ip = _a.ip, embedCodeToken = _a.embedCodeToken, toolsUrl = _a.toolsUrl, templateUrl = _a.templateUrl;
    var fontUrl = (_d = (_c = (_b = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _b === void 0 ? void 0 : _b.brand) === null || _c === void 0 ? void 0 : _c.font) === null || _d === void 0 ? void 0 : _d.fontUrl;
    var embeddedStyles = (_e = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _e === void 0 ? void 0 : _e.features.embeddedStyles;
    var outputStyles = embeddedStyles ? embeddedStyles.replace(/^<style\b[^>]*>|<\/style>$/g, '') : null;
    var extractedMeta = extractMetaTag(embeddedStyles);
    var name = extractedMeta === null || extractedMeta === void 0 ? void 0 : extractedMeta.name;
    var content = extractedMeta === null || extractedMeta === void 0 ? void 0 : extractedMeta.content;
    var fontFamily = ((_h = (_g = (_f = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _f === void 0 ? void 0 : _f.brand) === null || _g === void 0 ? void 0 : _g.font) === null || _h === void 0 ? void 0 : _h.fontFamily) || "AcuminProRegular";
    var jsonLDFlag = false;
    var convertSecondsToHms = function (d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        var hDisplay = h > 0 ? h + "H" : "";
        var mDisplay = m > 0 ? m + "M" : "";
        var sDisplay = s > 0 ? s + "S" : "";
        return 'PT' + hDisplay + mDisplay + sDisplay;
    };
    var fullPageData = (pageData === null || pageData === void 0 ? void 0 : pageData.page) || {};
    var type = "website";
    if (fullPageData.title === "Video Page") {
        type = "video.movie";
    }
    else if (fullPageData.title === "Show Page") {
        type = "video.tv_show";
    }
    else if (fullPageData.title === "Article Page") {
        type = "article";
    }
    else if (fullPageData.title === "Event Page" || fullPageData.title === "Game Detail Page") {
        type = "event";
    }
    var uploadDate, videoUploadDate, seriesUploadDate, duration, pageTitle, description = "", thumbnailArray = [], showDetailModule = undefined, seasons = [], mappedSeasons = [], videoDetailModule = undefined;
    pageTitle = (_k = (_j = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _j === void 0 ? void 0 : _j.metadataMap) === null || _k === void 0 ? void 0 : _k.title;
    if (pageTitle === null) {
        pageTitle = ((_l = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _l === void 0 ? void 0 : _l.title) || title;
    }
    if (!((_m = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _m === void 0 ? void 0 : _m.isLiveStream) && (((_o = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _o === void 0 ? void 0 : _o.contentType) === 'VIDEO' || ((_p = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _p === void 0 ? void 0 : _p.contentType) === "SERIES")) {
        jsonLDFlag = true;
        var videoTitle = (_q = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _q === void 0 ? void 0 : _q.title;
        pageTitle = videoTitle === null || videoTitle === void 0 ? void 0 : videoTitle.replace(/["']/g, ""); //Replace Both Double and Single Quotes
        if (((_r = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _r === void 0 ? void 0 : _r.uploadDate) < 1e10) {
            videoUploadDate = new Date(((_s = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _s === void 0 ? void 0 : _s.uploadDate) * 1000);
        }
        else {
            videoUploadDate = new Date((_t = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _t === void 0 ? void 0 : _t.uploadDate);
        }
        uploadDate = videoUploadDate.toISOString();
        seriesUploadDate = videoUploadDate.toISOString().slice(0, 10);
        duration = ((_u = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _u === void 0 ? void 0 : _u.duration) ? convertSecondsToHms((_v = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _v === void 0 ? void 0 : _v.duration) : null;
        var thumbnailUrl = (_w = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _w === void 0 ? void 0 : _w.thumbnailUrl;
        var thumbnailValues = thumbnailUrl && Object.values(thumbnailUrl);
        var thumbnailString = thumbnailValues && thumbnailValues.filter(function (ele) { return ele; }).toString();
        if (thumbnailString && thumbnailString.includes("impolicy")) {
            var srcSplit = thumbnailString.split("impolicy");
            thumbnailString = "".concat(srcSplit[0], "impolicy=resize&w=1920&h=1080");
        }
        else {
            thumbnailString = "".concat(thumbnailString, "?impolicy=resize&w=1920&h=1080");
        }
        thumbnailArray = thumbnailString && thumbnailString.split(",");
        if (((_x = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _x === void 0 ? void 0 : _x.contentType) === "VIDEO") {
            videoDetailModule = (_y = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.modules) === null || _y === void 0 ? void 0 : _y.filter(function (module) { return module.moduleType === "VideoDetailModule"; });
            var videoDescription = (_2 = (_1 = (_0 = (_z = videoDetailModule === null || videoDetailModule === void 0 ? void 0 : videoDetailModule[0]) === null || _z === void 0 ? void 0 : _z.contentData) === null || _0 === void 0 ? void 0 : _0[0]) === null || _1 === void 0 ? void 0 : _1.gist) === null || _2 === void 0 ? void 0 : _2.description;
            description = videoDescription ? videoDescription === null || videoDescription === void 0 ? void 0 : videoDescription.replace(/["']/g, "").replace(/\n/g, " ") : "";
        }
        if (((_3 = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _3 === void 0 ? void 0 : _3.contentType) === "SERIES") {
            showDetailModule = (_4 = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.modules) === null || _4 === void 0 ? void 0 : _4.filter(function (module) { return module.moduleType === "ShowDetailModule"; });
            var seriesDescription = (_8 = (_7 = (_6 = (_5 = showDetailModule === null || showDetailModule === void 0 ? void 0 : showDetailModule[0]) === null || _5 === void 0 ? void 0 : _5.contentData) === null || _6 === void 0 ? void 0 : _6[0]) === null || _7 === void 0 ? void 0 : _7.gist) === null || _8 === void 0 ? void 0 : _8.description;
            description = seriesDescription ? seriesDescription === null || seriesDescription === void 0 ? void 0 : seriesDescription.replace(/["']/g, "").replace(/\n/g, " ") : "";
            seasons = (_12 = (_11 = (_10 = (_9 = showDetailModule === null || showDetailModule === void 0 ? void 0 : showDetailModule[0]) === null || _9 === void 0 ? void 0 : _9.contentData) === null || _10 === void 0 ? void 0 : _10[0]) === null || _11 === void 0 ? void 0 : _11.seasons) !== null && _12 !== void 0 ? _12 : [];
            mappedSeasons = seasons.map(function (season, index) {
                var seasonData = {
                    "@type": "TVSeason",
                    name: season.title,
                    numberOfEpisodes: season.episodes.length.toString(),
                };
                seasonData.episode = season.episodes.map(function (episode, epIndex) { return ({
                    "@type": "TVEpisode",
                    episodeNumber: (epIndex + 1).toString(),
                    name: episode.title,
                    duration: convertSecondsToHms(episode.runtime)
                }); });
                return seasonData;
            });
        }
    }
    var jsonLDInput;
    if (jsonLDFlag) {
        if (((_13 = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _13 === void 0 ? void 0 : _13.contentType) === 'VIDEO') {
            jsonLDInput = "{\n        \"@context\": \"https://schema.org\",\n        \"@type\": \"VideoObject\",\n        \"name\": \"".concat(pageTitle, "\",\n        \"description\": \"").concat(description, "\",\n        \"thumbnailUrl\": ").concat(JSON.stringify(thumbnailArray && thumbnailArray.length > 0 ? thumbnailArray[0] : null), ",\n        \"uploadDate\": \"").concat(uploadDate, "\",\n        \"duration\": \"").concat(duration, "\",\n        \"contentUrl\": \"https://").concat(browserDetails.host).concat((_14 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _14 === void 0 ? void 0 : _14.metadataMap.contentUrl, "\"\n      }");
        }
        else if (((_15 = fullPageData === null || fullPageData === void 0 ? void 0 : fullPageData.metadataMap) === null || _15 === void 0 ? void 0 : _15.contentType) === "SERIES") {
            jsonLDInput = "{\n        \"@context\": \"https://schema.org\",\n        \"@type\": \"TVSeries\",\n        \"name\": \"".concat(pageTitle, "\",\n        \"containsSeason\" : ").concat(JSON.stringify(mappedSeasons ? mappedSeasons : []), ",\n        \"description\": \"").concat(description, "\",\n        \"thumbnailUrl\": ").concat(JSON.stringify(thumbnailArray && thumbnailArray.length > 0 ? thumbnailArray[0] : null), ",\n        \"datePublished\": \"").concat(seriesUploadDate, "\",\n        \"url\": \"https://").concat(browserDetails.host).concat((_16 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _16 === void 0 ? void 0 : _16.metadataMap.contentUrl, "\"\n      }");
        }
    }
    var appData = "window.apiBaseUrl=".concat(JSON.stringify(apiBaseUrl), "; window.sseBaseUrl=").concat(JSON.stringify(sseBaseUrl), "; window.xApiKey=").concat(JSON.stringify(xApiKey), "; window.cachedAPI=").concat(JSON.stringify(cachedAPI), "; window.app_data = ").concat(JSON.stringify(siteConfig), "; window.page_data = ").concat(JSON.stringify(pageData), "; window.browserDetails = ").concat(JSON.stringify(browserDetails), "; window.analyticsBaseUrl=").concat(JSON.stringify(analyticsBaseUrl), ";  window.wssBaseUrl=").concat(JSON.stringify(wssBaseUrl), "; window.env=").concat(JSON.stringify(env), "; window.ip=").concat(JSON.stringify(ip), "; window.embedCodeToken=").concat(JSON.stringify(embedCodeToken), "; window.toolsUrl=").concat(JSON.stringify(toolsUrl), "; window.templateUrl=").concat(JSON.stringify(templateUrl));
    return (React.createElement("html", { lang: "en", style: { "margin": 0 } },
        React.createElement("head", null,
            React.createElement("meta", { charSet: "utf-8" }),
            React.createElement("meta", { httpEquiv: "x-dns-prefetch-control", content: "on" }),
            React.createElement("title", null, pageTitle),
            name && content && React.createElement("meta", { name: name, content: content }),
            React.createElement("meta", { property: "og:url", content: "".concat(('https://' + (browserDetails === null || browserDetails === void 0 ? void 0 : browserDetails.host) + (browserDetails === null || browserDetails === void 0 ? void 0 : browserDetails.path)) || (browserDetails === null || browserDetails === void 0 ? void 0 : browserDetails.host) || "") }),
            React.createElement("meta", { name: "viewport", content: "width=device-width, initial-scale=1" }),
            React.createElement("meta", { name: "description", content: "".concat(((_18 = (_17 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _17 === void 0 ? void 0 : _17.metadataMap) === null || _18 === void 0 ? void 0 : _18.description) || "") }),
            React.createElement("meta", { name: "keywords", content: "".concat(((_20 = (_19 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _19 === void 0 ? void 0 : _19.metadataMap) === null || _20 === void 0 ? void 0 : _20.keywords) || "") }),
            React.createElement("meta", { property: "og:type", content: "".concat(((_22 = (_21 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _21 === void 0 ? void 0 : _21.metadataMap) === null || _22 === void 0 ? void 0 : _22.contentType) ? ((_24 = (_23 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _23 === void 0 ? void 0 : _23.metadataMap) === null || _24 === void 0 ? void 0 : _24.contentType).toLowerCase() : "website") }),
            React.createElement("meta", { property: "og:title", content: "".concat(pageTitle || "") }),
            React.createElement("meta", { property: "og:description", content: "".concat(((_26 = (_25 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _25 === void 0 ? void 0 : _25.metadataMap) === null || _26 === void 0 ? void 0 : _26.description) || "") }),
            React.createElement("meta", { property: "og:site_name", content: "".concat(((_27 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _27 === void 0 ? void 0 : _27.site) || "") }),
            React.createElement("meta", { name: "apple-mobile-web-app-capable", content: "yes" }),
            React.createElement("meta", { name: "apple-mobile-web-app-status-bar-style", content: "black" }),
            React.createElement("meta", { name: "apple-mobile-web-app-title", content: "".concat(((_28 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _28 === void 0 ? void 0 : _28.site) || "") }),
            React.createElement("meta", { property: "og:image", content: "".concat(((_31 = (_30 = (_29 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _29 === void 0 ? void 0 : _29.metadataMap) === null || _30 === void 0 ? void 0 : _30.thumbnailUrl) === null || _31 === void 0 ? void 0 : _31.r16x9) || ((_33 = (_32 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _32 === void 0 ? void 0 : _32.images) === null || _33 === void 0 ? void 0 : _33.placeholderCover), "?impolicy=resize&w=1920&h=1080") }),
            React.createElement("link", { rel: "shortcut icon", type: "image/x-icon", href: "".concat(((_36 = (_35 = (_34 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsPlatform) === null || _34 === void 0 ? void 0 : _34.images) === null || _35 === void 0 ? void 0 : _35.favicon) === null || _36 === void 0 ? void 0 : _36.imageUri) || "") }),
            React.createElement("link", { rel: "canonical", href: "".concat(('https://' + (browserDetails === null || browserDetails === void 0 ? void 0 : browserDetails.host) + (browserDetails === null || browserDetails === void 0 ? void 0 : browserDetails.path))) }),
            React.createElement("meta", { name: "twitter:card", content: "summary_large_image" }),
            React.createElement("meta", { name: "twitter:site", content: "@".concat(((_37 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _37 === void 0 ? void 0 : _37.site) || "") }),
            React.createElement("meta", { name: "twitter:creator", content: "@".concat(((_38 = siteConfig === null || siteConfig === void 0 ? void 0 : siteConfig.appcmsMain) === null || _38 === void 0 ? void 0 : _38.site) || "") }),
            React.createElement("meta", { property: "twitter:title", content: "".concat(pageTitle || "") }),
            React.createElement("meta", { property: "twitter:description", content: "".concat(((_40 = (_39 = pageData === null || pageData === void 0 ? void 0 : pageData.page) === null || _39 === void 0 ? void 0 : _39.metadataMap) === null || _40 === void 0 ? void 0 : _40.description) || "") }),
            fontUrl && React.createElement("link", { href: "".concat(fontUrl, "&display=swap"), rel: "stylesheet" }),
            React.createElement("link", { rel: "preconnect", href: "https://www.googletagmanager.com" }),
            React.createElement("link", { rel: "preconnect", href: "https://sp.auth-staging.adobe.com" }),
            React.createElement("link", { rel: "preconnect", href: "https://api.auth.adobe.com" }),
            React.createElement("link", { rel: "preconnect", href: "https://entitlement.auth-staging.adobe.com" }),
            React.createElement("link", { rel: "preconnect", href: "https://uswidgets.fn.sportradar.com" }),
            embeddedStyles && !(embeddedStyles.includes("meta")) && React.createElement("style", { dangerouslySetInnerHTML: { __html: outputStyles || "" } })),
        React.createElement("body", { className: "site-background-color site-color", style: {
                "margin": 0,
                "overflowX": "hidden",
                "textRendering": "auto",
                "fontSize": "1em",
                "fontFamily": "".concat(fontFamily, " !important")
            } },
            React.createElement("noscript", { dangerouslySetInnerHTML: {
                    __html: "<b>Enable JavaScript to run this app.</b>"
                } }),
            children,
            React.createElement("script", { dangerouslySetInnerHTML: {
                    __html: appData
                }, integrity: subResourceIntegrity(appData) }),
            jsonLDFlag ? (React.createElement("script", { type: "application/ld+json", dangerouslySetInnerHTML: {
                    __html: jsonLDInput
                }, integrity: subResourceIntegrity(jsonLDInput) })) : '')));
}
