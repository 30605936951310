var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import analytics from '@viewlift/analytics/analytics-core/index.cjs';
import googleAnalytics from '@viewlift/analytics/google-analytics/index.cjs';
import userBeacon from '@viewlift/analytics/user-beacon/index.cjs';
import fbPixel from '@viewlift/analytics/facebook/index.cjs';
import localforage from 'localforage';
import { parseJSON } from "../OnBoarding-V2/utils/parseJSON";
import { getDeviceId, getToken } from "../helpers";
import Cookies from 'js-cookie';
export default function trackEvent(eventName, payload, gaId) {
    return __awaiter(this, void 0, void 0, function () {
        var commonPayload, getCookie, getparsedCookie, oneTrustCookie, trackingID, userData, userSubscriptionPlanId, userSubscriptionPlanName, currentSelectedPlanObj, currentSelectedPlanId, currentSelectedPlanName, userProperties;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        return __generator(this, function (_m) {
            switch (_m.label) {
                case 0:
                    commonPayload = {
                        platform: 'Web',
                        etstamp: Date.now(),
                        device_id: getDeviceId() || "",
                    };
                    getCookie = Cookies.get("cc_cookie");
                    getparsedCookie = getCookie && JSON.parse(getCookie);
                    oneTrustCookie = window === null || window === void 0 ? void 0 : window.fireGaEvents;
                    if (!(((_a = getparsedCookie === null || getparsedCookie === void 0 ? void 0 : getparsedCookie.level) === null || _a === void 0 ? void 0 : _a.includes("performanceCookie")) || oneTrustCookie)) return [3 /*break*/, 2];
                    if (!(typeof window !== 'undefined' && (window === null || window === void 0 ? void 0 : window.app_data))) return [3 /*break*/, 2];
                    trackingID = gaId || ((_d = (_c = (_b = window === null || window === void 0 ? void 0 : window.app_data) === null || _b === void 0 ? void 0 : _b.appcmsPlatform) === null || _c === void 0 ? void 0 : _c.analytics) === null || _d === void 0 ? void 0 : _d.googleAnalyticsId);
                    if (window === null || window === void 0 ? void 0 : window.dataLayer) {
                        window.dataLayer.push(__assign({ 'event': eventName }, payload));
                    }
                    if (!trackingID) return [3 /*break*/, 2];
                    return [4 /*yield*/, localforage.getItem("AuthenticationStore")];
                case 1:
                    userData = _m.sent();
                    userSubscriptionPlanId = ((_f = (_e = userData === null || userData === void 0 ? void 0 : userData.user) === null || _e === void 0 ? void 0 : _e.monetizationPlan) === null || _f === void 0 ? void 0 : _f.id) || '';
                    userSubscriptionPlanName = ((_h = (_g = userData === null || userData === void 0 ? void 0 : userData.user) === null || _g === void 0 ? void 0 : _g.monetizationPlan) === null || _h === void 0 ? void 0 : _h.name) || '';
                    currentSelectedPlanObj = sessionStorage.getItem('currentPlan') ? parseJSON(sessionStorage.getItem('currentPlan')) : '';
                    currentSelectedPlanId = '';
                    currentSelectedPlanName = '';
                    if (currentSelectedPlanObj) {
                        currentSelectedPlanId = currentSelectedPlanObj === null || currentSelectedPlanObj === void 0 ? void 0 : currentSelectedPlanObj.id;
                        currentSelectedPlanName = currentSelectedPlanObj === null || currentSelectedPlanObj === void 0 ? void 0 : currentSelectedPlanObj.name;
                    }
                    userProperties = {
                        device_platform: 'Web',
                        logged_in_status: ((_j = userData === null || userData === void 0 ? void 0 : userData.user) === null || _j === void 0 ? void 0 : _j.userId) ? 'Yes' : 'No',
                        subscribed_status: ((_k = userData === null || userData === void 0 ? void 0 : userData.user) === null || _k === void 0 ? void 0 : _k.isSubscribed) ? 'Yes' : 'No',
                        device_id: getDeviceId() || '',
                        subscription_plan: userSubscriptionPlanName || currentSelectedPlanName || '',
                        plan_id: userSubscriptionPlanId || currentSelectedPlanId || '',
                        user_city: ((_l = userData === null || userData === void 0 ? void 0 : userData.user) === null || _l === void 0 ? void 0 : _l.city) || ''
                    };
                    analytics({
                        app: 'analytics',
                        eventName: eventName,
                        plugins: [
                            googleAnalytics({
                                trackingId: trackingID,
                                initialize: function () {
                                },
                                payload: __assign(__assign({}, payload), commonPayload),
                                userProperties: userProperties ? userProperties : null
                            }),
                        ]
                    });
                    _m.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
export function trackUserEvent(payload) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, getToken()];
                case 1:
                    token = _d.sent();
                    if (typeof window !== 'undefined') {
                        return [2 /*return*/, analytics({
                                app: 'user-analytics',
                                apiBaseUrl: (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.apiConfig) === null || _c === void 0 ? void 0 : _c.userBeaconUrl,
                                token: token,
                                plugins: [
                                    userBeacon({
                                        initialize: function () {
                                        },
                                        payload: payload
                                    }),
                                ]
                            })];
                    }
                    return [2 /*return*/];
            }
        });
    });
}
export function trackFbEvent(eventName, payload) {
    var _a, _b, _c;
    var conversionApi = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.analytics) === null || _c === void 0 ? void 0 : _c.conversionApi;
    if (typeof window !== 'undefined') {
        getToken().then(function (token) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            analytics({
                app: 'fb-analytics',
                eventName: eventName,
                trackingBaseUrl: ((_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.apiConfig) === null || _c === void 0 ? void 0 : _c.trackingBaseUrl) || ((_e = (_d = window === null || window === void 0 ? void 0 : window.app_data) === null || _d === void 0 ? void 0 : _d.appcmsMain) === null || _e === void 0 ? void 0 : _e.trackingBaseUrl),
                apiBaseUrl: (_h = (_g = (_f = window === null || window === void 0 ? void 0 : window.app_data) === null || _f === void 0 ? void 0 : _f.appcmsMain) === null || _g === void 0 ? void 0 : _g.apiConfig) === null || _h === void 0 ? void 0 : _h.apiBaseUrl,
                eventType: "fbConversionAPI",
                platformDetails: {
                    site: (_k = (_j = window === null || window === void 0 ? void 0 : window.app_data) === null || _j === void 0 ? void 0 : _j.appcmsMain) === null || _k === void 0 ? void 0 : _k.internalName,
                    conversionApiEnabled: conversionApi === null || conversionApi === void 0 ? void 0 : conversionApi.conversionApi,
                    pixelId: conversionApi === null || conversionApi === void 0 ? void 0 : conversionApi.pixelID,
                    device: "web",
                    token: token
                },
                plugins: [
                    fbPixel({
                        initialize: function () {
                        },
                        payload: payload
                    }),
                ]
            });
        });
    }
}
export function trackPixelEvent(eventName, payload) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var conversionApi = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.analytics) === null || _c === void 0 ? void 0 : _c.conversionApi;
    if (typeof window !== 'undefined') {
        analytics({
            app: 'fb-analytics',
            eventName: eventName,
            trackingBaseUrl: ((_f = (_e = (_d = window === null || window === void 0 ? void 0 : window.app_data) === null || _d === void 0 ? void 0 : _d.appcmsMain) === null || _e === void 0 ? void 0 : _e.apiConfig) === null || _f === void 0 ? void 0 : _f.trackingBaseUrl) || ((_h = (_g = window === null || window === void 0 ? void 0 : window.app_data) === null || _g === void 0 ? void 0 : _g.appcmsMain) === null || _h === void 0 ? void 0 : _h.trackingBaseUrl),
            apiBaseUrl: (_l = (_k = (_j = window === null || window === void 0 ? void 0 : window.app_data) === null || _j === void 0 ? void 0 : _j.appcmsMain) === null || _k === void 0 ? void 0 : _k.apiConfig) === null || _l === void 0 ? void 0 : _l.apiBaseUrl,
            eventType: "fbPixel",
            platformDetails: {
                site: (_o = (_m = window === null || window === void 0 ? void 0 : window.app_data) === null || _m === void 0 ? void 0 : _m.appcmsMain) === null || _o === void 0 ? void 0 : _o.internalName,
                conversionApiEnabled: conversionApi === null || conversionApi === void 0 ? void 0 : conversionApi.conversionApi,
                pixelId: conversionApi === null || conversionApi === void 0 ? void 0 : conversionApi.pixelID
            },
            plugins: [
                fbPixel({
                    initialize: function () {
                    },
                    payload: payload
                }),
            ]
        });
    }
}
