// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CmsCta {
  cursor: pointer;
  position: fixed;
  top: 25px;
  right: 32px;
  display: flex;
  z-index: 99;
  flex-direction: column;
  gap: 20px;
}
.CmsCta-icon {
  align-self: end;
}
.CmsCta-menu {
  width: 180px;
  flex-direction: column;
}
.CmsCta-menu-link-dropDown {
  text-decoration: none;
  color: white;
  padding: 8px 10px;
  border: 1px solid var(--siteBorderColor);
}
.CmsCta-menu-link-dropDown.disabled {
  cursor: default;
  box-shadow: 1px 6px 10px -7px white;
  opacity: 0.4;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.CmsCta-menu.close {
  display: none;
}
.CmsCta-menu.open {
  display: flex;
}

@media (max-width: 1200px) {
  .CmsCta {
    right: 50px;
  }
}`, "",{"version":3,"sources":["webpack://./client/components/CmsMenu/index.scss"],"names":[],"mappings":"AAAA;EAEI,eAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,WAAA;EACA,sBAAA;EACA,SAAA;AAAJ;AAEI;EACI,eAAA;AAAR;AAGI;EACI,YAAA;EACA,sBAAA;AADR;AAGQ;EACI,qBAAA;EACA,YAAA;EACA,iBAAA;EACA,wCAAA;AADZ;AAGY;EACI,eAAA;EACA,mCAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;AADhB;AAKQ;EACI,aAAA;AAHZ;AAMQ;EACI,aAAA;AAJZ;;AAWA;EACI;IACI,WAAA;EARN;AACF","sourcesContent":[".CmsCta{\n\n    cursor: pointer;\n    position: fixed;\n    top: 25px;\n    right: 32px;\n    display: flex;\n    z-index: 99;\n    flex-direction: column;\n    gap:20px;\n\n    &-icon{\n        align-self: end;\n    }    \n\n    &-menu{\n        width: 180px;\n        flex-direction: column;\n\n        &-link-dropDown{\n            text-decoration: none;\n            color: white;\n            padding: 8px 10px;\n            border: 1px solid var(--siteBorderColor);;\n\n            &.disabled{\n                cursor: default;\n                box-shadow: 1px 6px 10px -7px white;\n                opacity: 0.4;\n                display: flex;\n                justify-content: space-between;\n                gap: 10px;\n            }\n        }\n\n        &.close{\n            display: none;\n        }\n\n        &.open{\n            display: flex;\n        }\n    }\n\n\n}\n\n@media (max-width:1200px){\n    .CmsCta{\n        right: 50px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
