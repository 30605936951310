// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personalization-banner-container {
  padding: 0px 70px 0px 82px;
  width: 100%;
}
.personalization-banner-container .personalization-banner {
  width: calc(100% - 151px);
  position: absolute;
  z-index: 11;
  margin-top: 70px;
  padding: 20px 5px;
  background-color: var(--siteBannerBackgroundColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
}
.personalization-banner-container .personalization-banner .banner-text {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.personalization-banner-container .personalization-banner .banner-text div {
  text-align: left;
  color: var(--siteColor);
  font-size: 1rem;
}
.personalization-banner-container .personalization-banner .banner-text div .hyperlink {
  color: var(--siteColor);
  cursor: pointer;
}
.personalization-banner-container .personalization-banner .cancel-icon {
  cursor: pointer;
}
.personalization-banner-container .personalization-banner .cancel-icon svg path {
  fill: var(--siteColor);
}

@media (max-width: 680px) {
  .personalization-banner-container {
    padding: 0;
  }
  .personalization-banner-container .personalization-banner {
    width: 100%;
    position: fixed;
    bottom: 0px;
    top: initial;
    padding: 20px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./client/modules/PersonalizationBanner/index.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,WAAA;AACJ;AAAI;EACI,yBAAA;EACA,kBAAA;EACA,WAAA;EAEA,gBAAA;EACA,iBAAA;EACA,kDAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;AACR;AACQ;EACI,WAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;AACZ;AAAY;EACI,gBAAA;EACA,uBAAA;EACA,eAAA;AAEhB;AADgB;EACI,uBAAA;EACA,eAAA;AAGpB;AAEQ;EACI,eAAA;AAAZ;AAEgB;EACI,sBAAA;AAApB;;AAOA;EACI;IACI,UAAA;EAJN;EAKM;IACI,WAAA;IACA,eAAA;IACA,WAAA;IACA,YAAA;IACA,kBAAA;EAHV;AACF","sourcesContent":[".personalization-banner-container{\n    padding: 0px 70px 0px 82px;\n    width: 100%;\n    .personalization-banner{\n        width: calc(100% - 151px);\n        position: absolute;\n        z-index: 11;\n        // top: 0px;\n        margin-top: 70px;\n        padding: 20px 5px;\n        background-color: var(--siteBannerBackgroundColor);\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        column-gap: 10px;\n\n        .banner-text{\n            width: 100%;\n            display: flex;\n            justify-content: center;\n            text-align: center;\n            div {\n                text-align: left;\n                color: var(--siteColor);\n                font-size: 1rem;\n                .hyperlink {\n                    color: var(--siteColor);\n                    cursor: pointer;\n                }\n            }\n        }\n        \n        .cancel-icon{\n            cursor: pointer;\n            svg {\n                path{\n                    fill: var(--siteColor);\n                }\n            }\n        }\n    }\n}\n\n@media (max-width : 680px) {\n    .personalization-banner-container{\n        padding: 0;\n        .personalization-banner {\n            width: 100%;\n            position: fixed;\n            bottom: 0px;\n            top: initial;\n            padding: 20px 20px;\n        }\n    }  \n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
