// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.module-loader-wrapper #brand-loader {
  position: fixed;
  background: rgba(0, 0, 0, 0.6705882353);
  height: 100vh;
  width: 100vw;
}
.module-loader-wrapper #brand-loader img {
  top: 50%;
}`, "",{"version":3,"sources":["webpack://./client/components/ModuleLoader/style.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,uCAAA;EACA,aAAA;EACA,YAAA;AAAR;AACQ;EACI,QAAA;AACZ","sourcesContent":[".module-loader-wrapper {\n    #brand-loader {\n        position: fixed;\n        background: #000000ab;\n        height: 100vh;\n        width: 100vw;\n        img {\n            top: 50%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
