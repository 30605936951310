// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-color {
  color: var(--siteColor, #fff);
}

.site-background-color {
  background: var(--siteBackgroundColor, #000);
}

.site-active-tab-color {
  color: var(--siteCtaBackgroundColor, #000);
  border-bottom: 4px solid var(--siteCtaBackgroundColor, #000);
}

.site-border-radius {
  border-radius: var(--siteBorderRadius, 5px);
}

.site-border-width {
  border-width: var(--siteBorderWidth, 3px);
}

.site-cta-color {
  color: var(--siteCtaColor, #fff);
}

.site-cta-background-color {
  background-color: var(--siteCtaBackgroundColor, #000);
}

.site-box-shadow {
  background-color: var(--siteBoxShadow, #000);
}

.monetization-cta-primary-bg-color {
  background-color: var(--siteCtaBackgroundColor, #000);
}`, "",{"version":3,"sources":["webpack://./client/styles/siteStyles.scss"],"names":[],"mappings":"AAUA;EACI,6BAAA;AATJ;;AAWA;EACI,4CAAA;AARJ;;AAUA;EACI,0CAAA;EACA,4DAAA;AAPJ;;AASA;EACI,2CAAA;AANJ;;AAQA;EACI,yCAAA;AALJ;;AAOA;EACI,gCAAA;AAJJ;;AAMA;EACI,qDAAA;AAHJ;;AAKA;EACI,4CAAA;AAFJ;;AAIA;EACI,qDAAA;AADJ","sourcesContent":["$siteColor: #fff;\n$siteBorderColor: #000;\n$siteBackgroundColor: #000;\n$siteBorderWidth: 3px;\n$siteBorderRadius: 5px;\n$siteBorderStyle : solid;\n$siteCtaColor : #fff;\n$siteCtaBackgroundColor: #000;\n$siteBoxShadow : #000;\n\n.site-color {\n    color: var(--siteColor, $siteColor);\n}\n.site-background-color {\n    background: var(--siteBackgroundColor, $siteBackgroundColor);\n}\n.site-active-tab-color {\n    color: var(--siteCtaBackgroundColor, $siteCtaBackgroundColor);\n    border-bottom: 4px solid var(--siteCtaBackgroundColor, $siteCtaBackgroundColor);\n}\n.site-border-radius {\n    border-radius: var(--siteBorderRadius, $siteBorderRadius);\n}\n.site-border-width {\n    border-width: var(--siteBorderWidth, $siteBorderWidth);\n}\n.site-cta-color {\n    color : var(--siteCtaColor, $siteCtaColor);\n}\n.site-cta-background-color {\n    background-color : var(--siteCtaBackgroundColor, $siteCtaBackgroundColor);\n}\n.site-box-shadow {\n    background-color : var(--siteBoxShadow, $siteBoxShadow);\n}\n.monetization-cta-primary-bg-color {\n    background-color : var(--siteCtaBackgroundColor, $siteCtaBackgroundColor);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
