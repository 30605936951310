/**
 * The function `logger` logs messages with different levels (info, error, warn, debug) based on the
 * environment.
 * @param {string | number | object} message
 * @param {'info' | 'warn' | 'error' | 'debug'} [level=info]
 * @returns void
 */
export default function logger(message, level = 'info', isServerLog = false) {
  if (process.env.NODE_ENV === 'production') return;

  const timestamp = new Date().toISOString();
  const logMessage = `[${level.toUpperCase()}] | ${timestamp} | ${typeof message === "object" ? JSON.stringify(message) : message}`;

  if (isServerLog) {
    fetch('/api/log', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ payload: message, level })
    }).then(res => {
      if (!res.ok) console.error('Failed to log message to server:', res.statusText);
      const data = res.json(); // {message: string, uuid: 'uuid'}
      console.info(data)
    });
  }

  switch (level) {
    case 'error':
      return console.error(logMessage);
    case 'warn':
      return console.warn(logMessage);
    case 'debug':
      return console.debug(logMessage);
    case 'info':
      return console.info(logMessage);
    default:
      return console.log(logMessage);
  }
}
